import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'outline',
    'button'
  ]

  toggle(event) {
    var isHidden = this.outlineTarget.classList.contains('hide')

    if (isHidden) {
      this.buttonTarget.lastChild.classList.add('fa-angle-down')
      this.buttonTarget.lastChild.classList.remove('fa-angle-up')
      event.srcElement.setAttribute('aria-expanded', true);
      this.outlineTarget.classList.remove('hide')
    } else {
      this.buttonTarget.lastChild.classList.add('fa-angle-up')
      this.buttonTarget.lastChild.classList.remove('fa-angle-down')
      event.srcElement.setAttribute('aria-expanded', false);
      this.outlineTarget.classList.add('hide')
    }
  }
}
