import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'description',
    'duration',
    'image',
    'button',
    'path',
    'percentComplete',
    'percentCompleteSr',
    'progressBar',
    'sectionDescriptions',
    'whoThisIsFor'
  ]

  bind(data) {
    this.descriptionTarget.innerHTML = data.description
    let percentComplete = data.percentComplete
    this.percentCompleteTarget.innerHTML = percentComplete
    this.percentCompleteSrTarget.innerHTML = percentComplete
    this.durationTarget.innerHTML = data.duration
    this.buttonTarget.innerHTML = data.btnTitle
    this.buttonTarget.href = data.path
    this.imageTarget.src = data.imageSrc
    this.imageTarget.alt = data.imageAlt
    this.whoThisIsForTarget.innerHTML = data.whoThisIsFor

    let sectionDescriptions = data.sectionDescriptions.split("|");

    var ul = this.sectionDescriptionsTarget
    while (ul.firstChild) {
      ul.removeChild(ul.firstChild);
    }

    for (var i = 0; i < sectionDescriptions.length; i++) {
      var li = document.createElement("li")
      li.appendChild(document.createTextNode(sectionDescriptions[i]))
      ul.appendChild(li)
    }

    this.progressBarTarget.setAttribute('aria-valuenow', percentComplete)
    this.progressBarTarget.style["width"] = percentComplete + "%"
  }
}
