import '@stimulus/polyfills'
import 'whatwg-fetch'
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'nextButton',
    'completeButton'
  ]

  getStarted (event) {
    document.getElementById('slide-rows').classList.remove('hide')
    document.getElementById('get-started').classList.add('hide')

    setTimeout(() => {
      this.scrollAndPlayLatestSlide()
    }, 500)
  }

  scrollAndPlayLatestSlide () {
    this.latestSlideRowElement.scrollIntoView({behavior: "smooth"})
    this.latestSlideController.tappedPlayback(null)
  }

  tappedNext (event) {
    // TODO: Preload next slide: audio, video, etc.?
    this.nextButtonTarget.innerHTML = 'Loading...'

    fetch(this.data.get("next-slide-url"))
      .then(response => response.json())
      .then(jsonResponse => {
        let nextSlideId = jsonResponse['next_slide_id']
        if (jsonResponse['future_slide_id']) {
          this.data.set("future-slide-id", jsonResponse['future_slide_id'])
        } else {
          this.data.delete("future-slide-id")
        }
        this.nextButtonTarget.disabled = true

        if (nextSlideId) {
          fetch(this.slideUrl(nextSlideId))
            .then(response => response.text())
            .then(html => {
              this.latestSlideRowElement.insertAdjacentHTML('afterend', html);
              this.latestSlideRowElement.scrollIntoView({behavior: "smooth"})

              setTimeout(() => {
                const latestSlideController = this.latestSlideController

                if (jsonResponse['audio_url']) {
                  this.playAudio(jsonResponse['audio_url'], latestSlideController)
                }

                if (jsonResponse['video_url']) {
                  this.playVideo(latestSlideController)
                }
              }, 1000)
          })
        } else {
          this.showButton(true)
        }
    })
  }

  controllerForSlideEl (el) {
    return this.application.getControllerForElementAndIdentifier(
      el,
      'training-slide'
    )
  }

  playAudio (audioUrl, slideDelegate) {
    if (this.audio && this.audio.src != audioUrl) {
      this.stopAudio()
      this.audio = new Audio(audioUrl)
    } else if (!this.audio) {
      this.audio = new Audio(audioUrl)
    }

    if (this.video) {
      this.stopVideo()
    }

    this.nextButtonTarget.disabled = true

    var promise = this.audio.play()
    if (promise !== undefined) {
        promise.catch(function(error) {
          this.latestSlideController.toggleFallback(true)
        }.bind(this));
    }

    // this.audio.playbackRate = 16.0
    this.audio.addEventListener('timeupdate', (e) => {
      if (e.path && e.path[0]) {
        const audioElement = e.path[0]
        this.updateButtonTimer(audioElement.duration - audioElement.currentTime)
      } else if (this.audio) {
        this.updateButtonTimer(this.audio.duration - this.audio.currentTime)
      }
    })


    if (slideDelegate.element.contains(this.latestSlideRowElement)) {
      this.audio.addEventListener('play', () => {
        slideDelegate.showPauseImage()
        this.latestSlideRowElement.classList.remove('loading')
      })
      this.audio.addEventListener('ended', () => {
        slideDelegate.showPlayImage()
        this.showButton(false)
        this.nextButtonTarget.disabled = false
      })
    } else {
      this.audio.addEventListener('play', () => {
        slideDelegate.showPauseImage()
        this.latestSlideRowElement.classList.remove('loading')
      })
      this.audio.addEventListener('ended', () => {
        slideDelegate.showPlayImage()
      })
    }

    const playbackButtons = document.getElementsByClassName('playback-button')
    for (var i = playbackButtons.length - 1; i >= 0; i--) {
      let playbackImage = playbackButtons[i].firstElementChild
      playbackImage.classList.add('fa-play')
      playbackImage.classList.remove('fa-pause')
    }
  }

  pauseAudio () {
    if (this.audio) {
      this.audio.pause()
    }
  }

  stopAudio () {
    if (this.audio) {
      this.audio.pause()
      this.audio.currentTime = 0
      this.audio = null
    }
  }

  playVideo (slideDelegate) {
    const videoElement = slideDelegate.videoElement

    if (this.video && this.video != videoElement) {
      this.stopVideo()
      this.video = videoElement
    } else if (!this.video) {
      this.video = videoElement
    }

    if (this.audio) {
      this.stopAudio()
    }

    this.nextButtonTarget.disabled = true

    // videoElement.playbackRate = 16.0
    var promise = videoElement.play()
    if (promise !== undefined) {
      promise.catch(function(error) {
        this.latestSlideController.toggleFallback(true)
      }.bind(this));
    }

    this.video.addEventListener('timeupdate', (e) => {
      if (e.path && e.path[0]) {
        const videoElement = e.path[0]
        this.updateButtonTimer(videoElement.duration - videoElement.currentTime)
      } else if (this.video) {
        this.updateButtonTimer(this.video.duration - this.video.currentTime)
      }
    })

    if (slideDelegate.element.contains(this.latestSlideRowElement)) {
      this.video.addEventListener('play', () => {
        slideDelegate.showPauseImage()
        this.latestSlideRowElement.classList.remove('loading')
      })
      this.video.addEventListener('ended', () => {
        slideDelegate.showPlayImage()
        this.showButton(false)
        this.nextButtonTarget.disabled = false
      })
    } else {
      this.video.addEventListener('play', () => {
        slideDelegate.showPauseImage()
        this.latestSlideRowElement.classList.remove('loading')
      })
      this.video.addEventListener('ended', () => {
        slideDelegate.showPlayImage()
      })
    }

    const playbackButtons = document.getElementsByClassName('playback-button')
    for (var i = playbackButtons.length - 1; i >= 0; i--) {
      playbackButtons[i].classList.remove('active')
      let playbackImage = playbackButtons[i].firstElementChild
      playbackImage.classList.add('fa-play')
      playbackImage.classList.remove('fa-pause')
    }
  }

  pauseVideo () {
    if (this.video) {
      this.video.pause()
    }
  }

  stopVideo () {
    if (this.video) {
      this.video.pause()
      this.video.currentTime = 0
    }
  }

  // Shows the correct button
  showButton (shouldFocus) {
    if (this.data.get("is-last-slide") == 'true') {
      this.nextButtonTarget.classList.add('invisible')
      this.completeButtonTarget.classList.remove('invisible')
    } else if (this.data.get("future-slide-id")) {
      this.nextButtonTarget.classList.remove('invisible')
      this.completeButtonTarget.classList.add('invisible')
      if (shouldFocus) { this.nextButtonTarget.focus() }
    } else {
      this.nextButtonTarget.classList.add('invisible')
      this.completeButtonTarget.classList.remove('invisible')
      if (shouldFocus) { this.completeButtonTarget.focus() }
    }
  }

  updateButtonTimer (time)   {
    var intTime = time | 0
    var seconds = intTime % 60;
    var minutes = Math.floor(intTime / 60);

    this.nextButtonTarget.innerHTML = '[' + ('0' + minutes).substr(-2) + ':' + ('0' + seconds).substr(-2) + '] Next'

    if (time < 0.01) {
      this.nextButtonTarget.disabled = false
      this.nextButtonTarget.innerHTML = 'Next'
    }
  }

  slideUrl (slideId) {
    return this.data.get("url") + slideId
  }

  get latestSlideController () {
    return this.controllerForSlideEl(this.latestSlideRowElement)
  }

  get latestSlideRowElement () {
    return this.slideRowElements[this.slideRowElements.length - 1]
  }

  get slideRowElements () {
    return this.element.getElementsByClassName('slide-row')
  }
}
