import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'resources'
  ]

  bind(data) {
    const parsedResources = JSON.parse(data.resources)

    let ul = this.resourcesTarget
    while (ul.firstChild) {
      ul.removeChild(ul.firstChild);
    }

    for (var i = 0; i < parsedResources.length; i++) {
      let li = document.createElement("li")
      let resource = parsedResources[i]
      let anchor = document.createElement('a')
      anchor.href = resource.url
      anchor.innerHTML = resource.link
      anchor.target = "_blank"
      li.appendChild(anchor)
      ul.appendChild(li)
    }
  }
}
