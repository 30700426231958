import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'text',
    'toggle',
  ]

  initialize() {
    this.toggleTarget.setAttribute('aria-controls', this.textTarget.id);

    if (document.inf_preferences.get('training.show_transcript')) {
      this.show()
    } else {
      this.hide()
    }
  }

  hide () {
    this.toggleTarget.innerHTML = "Show"
    this.toggleTarget.setAttribute('aria-expanded', false);
    this.textTarget.classList.add('hide')
    document.inf_preferences.unset('training.show_transcript')
  }

  show () {
    this.toggleTarget.innerHTML = "Hide"
    this.toggleTarget.setAttribute('aria-expanded', true);
    this.textTarget.classList.remove('hide')
    document.inf_preferences.set('training.show_transcript', 1)
  }

  toggle(event) {
    if (this.textTarget.classList.contains('hide')) {
      this.show()
    } else {
      this.hide()
    }
  }
}
