import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'box' ]

  updateFeedback(answer, correct) {
    this.boxTarget.classList.remove('hidden')

    var bodyElement = this.boxTarget.getElementsByClassName('body')[0]
    bodyElement.innerHTML = answer.feedback

    var titleElement = this.boxTarget.getElementsByClassName('title')[0]

    // TODO: Play audio

    var iconElement = this.boxTarget.getElementsByClassName('fas')[0]
    if (correct) {
      this.boxTarget.classList.add('correct')
      this.boxTarget.classList.remove('incorrect')
      titleElement.innerHTML = 'Correct!'
      iconElement.classList.add('fa-check-circle')
      iconElement.classList.remove('fa-times-circle')
    } else {
      this.boxTarget.classList.remove('correct')
      this.boxTarget.classList.add('incorrect')
      titleElement.innerHTML = 'Try again'
      iconElement.classList.remove('fa-check-circle')
      iconElement.classList.add('fa-times-circle')
    }
  }
}
