import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'rating', 'thumbs', 'submit' ]

  updateRating (e) {
    let rating = Number(e.srcElement.value)

    for (var i = this.ratingTarget.children.length - 1; i >= 0; i--) {
      let child = this.ratingTarget.children[i]
      if (child.tagName.toLowerCase() == "button") {
        let num = Number(child.value)
        child.classList.remove('selected')
        if (num <= rating) {
          child.classList.add('filled')
        } else {
          child.classList.remove('filled')
        }
      }
    }

    e.srcElement.classList.add('selected')
    this.ratingInput.value = rating
    this.enableSubmit()
  }

  updateThumbs (e) {
    let thumbRating = e.srcElement.value
    for (var i = this.thumbsTarget.children.length - 1; i >= 0; i--) {
      let child = this.thumbsTarget.children[i]
      if (child.tagName.toLowerCase() == "button") {
        let thumb = child.value
        if (child.value == thumbRating) {
          child.classList.add('selected')
        } else {
          child.classList.remove('selected')
        }
      }
    }

    this.thumbsInput.value = thumbRating
    this.enableSubmit()
  }

  enableSubmit () {
    if (this.ratingInput.value.length > 0 && this.thumbsInput.value.length > 0) {
      this.submitTarget.classList.remove('disabled')
    }
  }

  get thumbsInput() {
    return this.thumbsTarget.getElementsByTagName('input')[0]
  }

  get ratingInput() {
    return this.ratingTarget.getElementsByTagName('input')[0]
  }
}
