import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'question', 'feedback', 'submitButton' ]

  submit () {
    if (this.selectedAnswer) {
      let feedback = this.selectedAnswer.parentElement.dataset['feedback']

      let feedbackController = this.application.getControllerForElementAndIdentifier(
        this.feedbackTarget,
        "training-quiz-feedback"
      );
      let answerDataset = this.selectedAnswer.parentElement.dataset
      let answerId = this.selectedAnswer.parentElement.firstElementChild.value
      let isCorrect = answerId == this.questionTarget.dataset.answerId
      feedbackController.updateFeedback(answerDataset, isCorrect)

      this.feedbackTarget.focus();

      let answerIdElement = this.element.parentElement.querySelector('input[name="quiz[training_question_answer_id]"]')
      if (!answerIdElement.value.length > 0) {
        answerIdElement.value = answerId
        this.element.parentElement.querySelector('input[name="quiz[correct]"]').value = isCorrect ? 1 : 0
      }

      if (isCorrect) {
        this.fieldset.setAttribute("disabled", "disabled")
        this.submitButtonTarget.classList.add('hidden')
        document.getElementById('continue-button').classList.remove('invisible')
      }
    } else {
      alert('Select an answer to continue')
    }
  }

  get fieldset() {
    return this.questionTarget.querySelector('fieldset')
  }

  get selectedAnswer() {
    return this.questionTarget.querySelector('input:checked')
  }
}
