import { Controller } from "stimulus"
import 'cropperjs/dist/cropper.css'
import Cropper from "cropperjs"

export default class extends Controller {
  static targets = [
    'file',
    'preview',
    'cropX',
    'cropY',
    'cropW',
    'cropH'
  ]

  previewLoaded(src) {
    this.previewTarget.src = src

    setTimeout(() => {
      this.showCropper({
        autoCropArea: 1,
        viewMode: 2,
        dragMode: 'move',
        minContainerHeight: 640,
        aspectRatio: this.aspectRatio,
        width: this.width,
        height: this.height,
        crop: (e) => {
          this.cropXTarget.value = e.detail.x
          this.cropYTarget.value = e.detail.y
          this.cropWTarget.value = e.detail.width
          this.cropHTarget.value = e.detail.height
        }
      })
    }, 300)
  }

  showCropper(options) {
    this.cropper = new Cropper(this.previewTarget, options)
  }

  uploadChanged(e) {
    var file = this.fileTarget.files[0];
    var reader = new FileReader();

    reader.onload = (e) => {
      this.previewLoaded(e.target.result)
    }
    reader.readAsDataURL(file);
  }

  get aspectRatio () {
    return this.data.get("aspect-ratio") || 1
  }

  get width () {
    return this.data.get("width")
  }

  get height () {
    return this.data.get("height")
  }
}
