import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "bar" ]

  connect () {
    this.update(0)
    this.barTarget.parentElement.classList.add('invisible')
  }

  update (pct) {
    if (pct > 99.9 || pct < 0.1) {
      setTimeout(() => {
        this.barTarget.parentElement.classList.add('invisible')
      }, 500)
    } else {
      this.barTarget.parentElement.classList.remove('invisible')
    }

    this.barTarget.style.width = `${pct}%`
    this.barTarget.setAttribute('aria-valuenow', pct)
    this.barTarget.firstElementChild.innerText = `${pct}% complete`
  }
}
