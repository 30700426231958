import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['title']

  open() {
    document.body.classList.add("modal-open")
    this.element.setAttribute("style", "display: block")
    this.element.classList.add("in")
    document.body.innerHTML += '<div id="modal-backdrop" class="modal-backdrop fade in"></div>'
  }

  close() {
    document.body.classList.remove("modal-open")
    this.element.setAttribute("style", "display: none")
    this.element.removeAttribute("style")
    this.element.classList.remove("in")
    var backdrop = document.getElementsByClassName("modal-backdrop")[0]
    backdrop.parentNode.removeChild(backdrop)
  }

  setTitle(newTitle) {
    this.titleTarget.innerHTML = newTitle;
  }
}
