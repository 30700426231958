import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "modal" ]

  presentModal(event) {
    const modalType = event.srcElement.dataset.modalType

    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    )
    modalController.setTitle(event.currentTarget.dataset.title);

    let customModalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      `${modalType}-modal`
    )
    customModalController.bind(event.currentTarget.dataset);
    modalController.open();
  }
}
