import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'fontSize'
  ]

  toggleFontSize (event) {
    let fontSize = document.inf_preferences.get('a11y_fontSize')

    if (fontSize) {
      if (fontSize == "null") {
        fontSize = '2em'
        this.fontSizeTarget.innerHTML = 'Increase Font Size'
      } else if (fontSize == "2em") {
        fontSize = '2.5em'
        this.fontSizeTarget.innerHTML = 'Reset Font Size'
      } else {
        fontSize = null
        this.fontSizeTarget.innerHTML = 'Increase Font Size'
      }
    } else {
      fontSize = '2em'
      this.fontSizeTarget.innerHTML = 'Increase Font Size'
    }

    document.body.style.fontSize = fontSize
    document.inf_preferences.set('a11y_fontSize', fontSize)
  }

  toggleHighContrast (event) {
    if (document.body.classList.contains('high-contrast')) {
      document.body.classList.remove('high-contrast')
      document.inf_preferences.unset('a11y_highContrast')
  } else {
      document.body.classList.add('high-contrast')
      document.inf_preferences.set('a11y_highContrast', true)
    }
  }
}
