import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'loading',
    'playbackButton'
  ]

  connect () {
    if (!this.hasPlayed) {
      this.playbackButtonTarget.disabled = true
      if (!this.audioUrl && !this.videoElement) {
        this.slidesController.showButton(false)
      }
    } else {
      this.element.classList.remove('loading')
    }
  }

  isPlaying () {
    const playbackImage = this.playbackButtonTarget.firstElementChild
    return playbackImage.classList.contains('fa-pause')
  }

  tappedPlayback (e) {
    this.element.classList.remove('loading')
    this.element.classList.remove('fallback')
    this.slidesController.nextButtonTarget.classList.remove('hide')

    if (this.audioUrl) {
      if (this.isPlaying()) {
        this.slidesController.pauseAudio()
        this.showPlayImage()
      } else {
        this.slidesController.playAudio(this.audioUrl, this)
        this.showPauseImage()
      }
    } else if (this.videoElement) {
      if (this.isPlaying()) {
        this.videoElement.pause()
        this.slidesController.pauseVideo()
        this.showPlayImage()
      } else {
        this.videoElement.play()
        this.slidesController.playVideo(this)
        this.showPauseImage()
      }
    }
  }

  showPauseImage () {
    this.playbackButtonTarget.disabled = false
    const playbackImage = this.playbackButtonTarget.firstElementChild
    playbackImage.classList.add('fa-pause')
    playbackImage.classList.remove('fa-play')
    this.playbackButtonTarget.classList.add('active')
  }

  showPlayImage () {
    const playbackImage = this.playbackButtonTarget.firstElementChild
    playbackImage.classList.add('fa-play')
    playbackImage.classList.remove('fa-pause')
    this.playbackButtonTarget.classList.remove('active')
  }

  toggleFallback (shouldFallback) {
    this.showPlayImage()

    this.playbackButtonTarget.disabled = false
    const playbackText = this.playbackButtonTarget.lastElementChild
    this.element.classList.add('fallback')
    this.slidesController.nextButtonTarget.classList.add('hide')
    this.element.classList.remove('loading')
  }

  get audioUrl () {
    return this.data.get("audio-url")
  }

  get hasPlayed () {
    return this.data.get("has-played")
  }

  get slideId () {
    return this.data.get("id")
  }

  get videoElement () {
    return this.element.getElementsByTagName('video')[0]
  }

  get progressBarController () {
    return this.application.getControllerForElementAndIdentifier(
      this.element.getElementsByClassName('progress')[0],
      'progress-bar'
    )
  }

  get slidesController () {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller="training-slides"]'),
      'training-slides'
    )
  }
}
